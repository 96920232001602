import React from 'react';
import PropTypes from 'prop-types';

export default class Kapparot extends React.Component {
    static propTypes = {
        variantId: PropTypes.number,
        nameLabel: PropTypes.string,
        sexMaleLabel: PropTypes.string,
        sexFemaleLabel: PropTypes.string,
        presenceLabel: PropTypes.string,
        addMoreLabel: PropTypes.string
    };

    state = {
        items: [{}],
    };

    addFormItemHandler = () => {
        this.setState(prevState => ({
            items: [...prevState.items, {}]
        }));
    };

    removeFormItemHandler = (key) => {
        this.setState(prevState => ({
            items: prevState.items.filter((_, k) => (k !== key))
        }));
    };

    renderItem = (key, item) => {
        const nameSuffix = `line_items[${key}]`;

        return (
            <div key={`form-item#${key}`} className="form-group form-item">
                <input type="hidden" name={`${nameSuffix}[variant_id]`} value={this.props.variantId}/>
                <input type="hidden" name={`${nameSuffix}[quantity]`} value="1"/>
                <div className="col-md-8 name-input-container">
                    <input type="text"
                           name={`${nameSuffix}[notation_value][name]`}
                           placeholder={this.props.nameLabel}
                           className="form-control"/>
                </div>
                <div className="col-md-2 sex-input-container">
                    <label className="radio-inline">
                        <input type="radio"
                               name={`${nameSuffix}[notation_value][sex]`}
                               value="male"
                               defaultChecked="true"
                        /> {this.props.sexMaleLabel}
                    </label>
                    <label className="radio-inline">
                        <input type="radio"
                               name={`${nameSuffix}[notation_value][sex]`}
                               value="female"
                        /> {this.props.sexFemaleLabel}
                    </label>
                </div>
                <div className="col-md-2 presence-input-container">
                    <label className="checkbox-inline">
                        <input type="checkbox"
                               name={`${nameSuffix}[notation_value][presence]`}
                               value="true"
                               defaultChecked="true"
                        /> {this.props.presenceLabel}
                    </label>
                </div>
            </div>
        );
    };

    render() {
        const items = this.state.items;

        return (
            <>
                {items.map((item, key) => { return this.renderItem(key, item) })}
                <div className="form-group add-form-item">
                    <button type="button" className="btn btn-default" onClick={this.addFormItemHandler}>{this.props.addMoreLabel}</button>
                </div>
            </>
        );
    }
};

// import Rails from '@rails/ujs';
import Component from 'javascripts/component';
import SubscriptionPlan from 'javascripts/spree/storefront/SubscriptionPlan';
import Kapparot from 'javascripts/spree/storefront/Kapparot';
import MicroModal from 'micromodal';
import Autocomplete from '@tarekraafat/autocomplete.js';

/**
 * Initialize subscription plan components
 */
Component.initReact('component-subscription-plan-year', SubscriptionPlan);
Component.initReact('component-subscription-plan-month', SubscriptionPlan);

/**
 * Initialize kapparot component
 */
Component.initReact('component-kapparot', Kapparot);

/**
 * Initialize micro modal
 */
MicroModal.init({
    disableScroll: true
});

/**
 * Show modals on init if data-micromodal-show-on-init set
 */
const showModalsOnInitIfNeeded = () => {
    document.querySelectorAll('[data-micromodal-trigger][data-micromodal-show-on-init="true"]').forEach((micromodalTriggerElement) => {
        MicroModal.show(micromodalTriggerElement.getAttribute('data-micromodal-trigger'));
    });
}
showModalsOnInitIfNeeded();

/**
 * Accordion in program on course page
 */
const courseAccordionInit = () => {
    const courseAccordionHeaderElements = document.querySelectorAll('.course-accordion .course-accordion-item .course-accordion-header');

    courseAccordionHeaderElements.forEach((courseAccordionHeaderElement) => {
        courseAccordionHeaderElement.addEventListener('click', (event) => {
            event.currentTarget.closest('.course-accordion-item').classList.toggle('active');
        });
    });
};
courseAccordionInit();

/**
 * Tabs for subscription plans and lesson/lecture description
 */
const tabsInit = () => {
    const tabsElements = document.querySelectorAll('.js-tabs');

    tabsElements.forEach((tabsElement) => {
        const buttonElements = tabsElement.querySelectorAll('.js-tabs__header .js-tabs__header-button');
        const contentElements = tabsElement.querySelectorAll('.js-tabs__content .js-tabs__content-item');

        /**
         * Activates button and relevant content by index
         * @param {Number} index
         */
        const activate = (index) => {
            buttonElements.forEach((buttonElement, buttonIndex) => {
                if (buttonIndex === index) {
                    buttonElement.classList.add('active');
                } else {
                    buttonElement.classList.remove('active');
                }
            });

            contentElements.forEach((contentElement, contentIndex) => {
                if (contentIndex === index) {
                    contentElement.classList.remove('hidden');
                } else {
                    contentElement.classList.add('hidden');
                }
            });
        };

        /**
         * Process button click event
         */
        buttonElements.forEach((buttonElement) => {
            buttonElement.addEventListener('click', (event) => {
                const buttons = event.currentTarget.closest('.js-tabs__header').querySelectorAll('.js-tabs__header-button');
                const currentIndex = [...buttons].indexOf(event.currentTarget);
                activate(currentIndex);
            });
        });
    });
};
tabsInit();

/**
 * Filter on lessons (acts) page
 */
const universityIntroInit = () => {
    const universityIntroContentElement = document.querySelector('.university-intro-content');

    if (!universityIntroContentElement) {
        return;
    }

    document.querySelector('.university-search-toggle').addEventListener('click', (event) => {
        universityIntroContentElement.classList.toggle('university-intro-content--search');
    });

    const universityFilterElement = document.querySelector('.university-filter');
    const universityFilterOverlayElement = document.querySelector('.university-filter-overlay');

    const toggleFilters = () => {
        document.body.classList.toggle('scroll-locked');
        universityFilterElement.classList.toggle('show-filter');
        universityFilterOverlayElement.classList.toggle('university-filter-overlay--visible');
    };

    document.querySelector('.university-filter-toggle').addEventListener('click', (event) => {
        toggleFilters();
    });

    document.querySelector('.university-filter-header__close').addEventListener('click', (event) => {
        toggleFilters();
    });

    universityFilterOverlayElement.addEventListener('click', (event) => {
        toggleFilters();
    });
};
universityIntroInit();

/**
 * Autocomplete for search on lessons (acts) page
 */
const universitySearchAutocompleteInit = () => {
    const universitySearchAutocompleteSelector = '#university-search-autocomplete';

    if (!document.querySelector(universitySearchAutocompleteSelector)) {
        return;
    }

    const autocomplete = new Autocomplete({
        selector: universitySearchAutocompleteSelector,
        wrapper: false,
        debounce: 300,
        data: {
            src: async (query) => {
                try {
                    const path = autocomplete.input.getAttribute('data-autocomplete-path');

                    if (!path) {
                        return [];
                    }

                    const source = await fetch(`${path}?keyword=${query}`);
                    return await source.json();
                } catch (_error) {
                    return [];
                }
            },
            cache: false
        },
        resultsList: {
            class: 'university-search-autocomplete-results',
            noResults: false,
        },
        resultItem: {
            highlight: false,
            selected: 'selected',
        },
        events: {
            input: {
                focus() {
                    if (autocomplete.input.value.length) autocomplete.start();
                },
                selection(event) {
                    autocomplete.input.value = event.detail.selection.value;
                    autocomplete.input.closest('form').submit();
                },
            },
            list: {
                mouseover(_event) {
                    if (autocomplete.cursor < 0) return;

                    const resultList = autocomplete.input;
                    const selectedResultItem = autocomplete.list.getElementsByTagName('li')[autocomplete.cursor];

                    resultList.setAttribute('aria-activedescendant', '');
                    selectedResultItem.removeAttribute('aria-selected');
                    selectedResultItem.classList.remove('selected');

                    autocomplete.cursor = -1;
                }
            }
        },
        submit: true,
    });
};
universitySearchAutocompleteInit();

/**
 * Fixes ugly url after auth through facebook
 * @note maybe this should be moved to server side
 */
const omniauthFacebookFix = () => {
    if (window.location.hash && window.location.hash === '#_=_') {
        if (window.history && history.pushState) {
            window.history.pushState("", document.title, window.location.pathname);
        } else {
            var scroll = {
                top: document.body.scrollTop,
                left: document.body.scrollLeft
            };
            window.location.hash = '';
            document.body.scrollTop = scroll.top;
            document.body.scrollLeft = scroll.left;
        }
    }
};
omniauthFacebookFix();

/**
 * Init smooth scrolling when clicking on anchor link
 */
const anchorScrollInit = () => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
};
anchorScrollInit();

/**
 * Init legal terms and conditions checkbox
 */
const checkoutLegalTermsAndConditionsCheckboxInit = function() {
    const acceptCheckbox = document.getElementById('accept_legal_terms_and_conditions');

    if (!acceptCheckbox) {
        return;
    }

    const synchronizeCheckboxWithFormSubmits = function(checkbox) {
        const form = checkbox.closest('form');

        if (!form) {
            return;
        }

        form.querySelectorAll('[type=submit]').forEach((submit) => {
            if (checkbox.checked) {
                submit.disabled = false;
                submit.classList.remove('disabled');
            } else {
                submit.disabled = true;
                submit.classList.add('disabled');
            }
        });
    };

    acceptCheckbox.addEventListener('change', (event) => {
        synchronizeCheckboxWithFormSubmits(event.currentTarget);
    });
    synchronizeCheckboxWithFormSubmits(acceptCheckbox);
};
checkoutLegalTermsAndConditionsCheckboxInit();